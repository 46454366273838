@font-face{
    font-family:"TTCBold";
    src:url("fonts/TTCommons-Bold.ttf");
    font-weight:normal;font-style:normal
}
@font-face{
    font-family:"TTCBolditalic";
    src:url("fonts/TTCommons-BoldItalic.ttf");
    font-weight:normal;
    font-style:normal
}
@font-face{
    font-family:"TTCDemibold";
    src:url("fonts/TTCommons-DemiBold.ttf");
    font-weight:normal;font-style:normal
}
@font-face{
    font-family:"TTCDemiboldItalic";
    src:url("fonts/TTCommons-DemiBoldItalic.ttf");
    font-weight:normal;font-style:normal
}
@font-face{
    font-family:"TTCMedium";
    src:url("fonts/TTCommons-Medium.ttf");
    font-weight:normal;
    font-style:normal
}
@font-face{
    font-family:"TTCLight";
    src:url("fonts/TTCommons-Light.ttf");
    font-weight:normal;
    font-style:normal
}
@font-face{
    font-family:"TTCRegular";
    src:url("fonts/TTCommons-Regular.ttf");
    font-weight:normal;
    font-style:normal
}
@font-face{
    font-family:"TTCMeditalic";
    src:url("fonts/TTCommons-MediumItalic.ttf");
    font-weight:normal;
    font-style:normal
}
@font-face{
    font-family:"TTCItalic";
    src:url("fonts/TTCommons-Italic.ttf")
}

.customPicker{
    width: 100%;
    height: 25px;
    border-color: #DFDBDB;
    border-right-width: 1px;
    border-left-width: 1px;
    border-radius: 4px;
    color: #8A8A8A;
    font-weight: bold;
    padding-top: inherit;
}

.customPicker::placeholder {
    color: #8A8A8A;
}


/**
Inicia sección de estilos origen de alpha erp
**/

body {
    font-family: 'TTCRegular', sans-serif;
}

.input-alpha{
    border-radius:15vh;
    background-color:#7f7f7f ;
    padding:0 5px;height:35px;
    display:block;
    font-size:1rem;
    cursor:pointer;
    text-align:left;
    margin-bottom:10px;
    border:0px;color:#fff;
    width: 100%;
    font-family: TTCItalic;
}
.input-alpha::placeholder{color:#FFF}

.input-alpha:focus-visible{
    border:0px solid #7f7f7f;
}

.btn-alpha-success{
    width: 100%;
    height:35px;
    background-color:#59c6ea;
    border:0px;
    border-radius:25px;
    font-family:'TTCDemibold';
    font-size:1rem;
    color:#FFFFFF;
    padding-top:3px;
    -webkit-appearance:none;
    appearance:none;
    -moz-appearance:none;
    cursor:pointer;
}
.btn-alpha-success:hover{background:#0d758e}

.icons-alpha{
    color: #808080;
    font-size: 1.0em;
}

.filtro-text{
    color: #000;
    font-size: 0.8em;
}

.accordion-header{
    background-color: #E4F2F9;
    padding-top: 2px;
    padding-left: 5px;
    border-radius: 0.5rem;
}

.icon-colapse-position{
    float: right;
    padding-right: 1%;
}

.miniBtn
{
    border:1px solid #2D5375;
    border-radius:5px;
    background:#2D5375;
    padding:0 5px;
    margin:  -10px 0 0 5px;
    height:25px;
    display:block;
    font-size:12px;
    cursor:pointer;
    text-align:left;
    white-space:pre-wrap;
    overflow:hidden;
    text-overflow:initial;
    color: white;
}





.elemento-input{
    width:100%;
    border:1px solid #a9a9a9;
    border-radius:5px;
    background:#fff !important;
    padding:0 5px;
    height:25px;
    display:block;
    font-size:12px;
    font-family:TTCRegular;
    color:black;
    cursor:auto;
    text-align:left;
    white-space:pre-wrap;
    overflow:hidden;
    text-overflow:initial
}
.elemento-input:disabled{
    color:#a9a9a9 !important;
    cursor:default;
    cursor:not-allowed
}


.pad-element{
    margin-top: 0.4rem;
}

.pl-2{ padding-left: 2px;}
.pl-3{ padding-left: 3px;}
.pl-4{ padding-left: 4px;}
.pl-5{ padding-left: 5px;}
.pl-10{ padding-left: 10px;}

.pr-2{ padding-right: 2px;}
.pr-3{ padding-right: 3px;}
.pr-4{ padding-right: 4px;}
.pr-5{ padding-right: 5px;}
.pr-10{ padding-right: 10px;}


.pl-element{
    padding-left: 0.4rem;
}

.btn-alpha-black{
    width: 100%;
    border: 1px solid #4d4d4d;
    border-radius: 1rem;
    padding: 4px;
    display: table;
    cursor: pointer;
    height: 27px;
    background-color: #4d4d4d;
    font-family: TTCDemibold;
    color: #EEEEEE;
    font-size: 1rem;
}

.btn-alpha-black:hover{
    background-color: #707070;
}

.btn-alpha-black i{  float: left;}

.btn-alpha-danger{
    width: 95%;
    border: 1px solid;
    border-radius: 1rem;
    padding: 4px;
    display: table;
    cursor: pointer;
    margin: 5px;
    height: 27px;
    background-color: #db6161;
    border-color: #ef7272;
    font-family: TTCDemibold;
    color: #EEEEEE;
    font-size: 1rem;
}

.btn-alpha-danger:hover{background-color:#b53a3a;border-color:#b53a3a}
.btn-alpha-danger i{  float: left; padding-left: 0.3rem; font-size: 16px }


.btn-alpha-success-2{
    width: 100%;
    border-radius: 1rem;
    padding: 4px;
    display: table;
    cursor: pointer;
    height: 27px;
    background-color: #5fb48c;
    border: 1px solid #5fb48c;
    font-family: TTCDemibold;
    color: #FFFFFF;
    font-size: 1rem;
    margin: 5px 5px 3px;
}


.btn-alpha-success-2:hover{ background-color:#31895d;border-color:#31895d }
.btn-alpha-success-2 i{  float: left; padding-left: 0.3rem; font-size: 16px }

.btn-alpha-success-2-disabled{
    width: 100%;
    border: 1px solid;
    border-radius: 1rem;
    padding: 4px;
    display: table;
    height: 27px;
    background-color: #5fb48c;
    border-color: #5fb48c;
    opacity: 0.6;
    font-family: TTCDemibold;
    color: #FFFFFF;
    font-size: 1rem;
}

.th-header{
    height: 30px;
    background-color: #999999;
    color: #fff;
    display: table-cell;
    font-family: TTCItalic;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    font-size: 0.9rem;
}

.th-header-2{
    height: 30px;
    background-color: #999999;
    color: #fff;
    display: table-cell;
    font-family: TTCItalic;
    font-size: 0.9rem;
}

.table-alpha-filter td {
    font-family: TTCRegular;
    font-size: 1rem;
    color: #000;
}

.table-alpha-filter tr:nth-child(even){
    background-color: #DCF1FF;
}

.table-alpha-filter tr td:first-child{
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.table-alpha-filter tr td:last-child{
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.table-alpha-filter>tbody>tr:hover {
    background-color: #5F9BC4;
}

.table-alpha-filter>tbody>tr:hover td {
    color: #fff;
}

.set-font{
    font-family: TTCRegular;
}
.label-container{
    padding: 5px;
}

.titleSegment{
    width: 100%;
    background-color: #f2f2f2;
    border-radius: 0.5rem;
    font-family: TTCRegular;
}

.lbl-etiqueta{
    font-family: TTCRegular;
    font-size: 0.9rem;
    color:#000;
}

.lbl-etiqueta-mobile{
    font-family: TTCRegular;
    font-size: 0.9rem;
    color:#666666;
}

.lbl-etiqueta-desk{
    font-family: TTCRegular;
    font-size: 1rem;
    color:#666666;
}
.lbl-etiqueta-desk-2{
    font-family: TTCRegular;
    font-size: 1.3rem;
    color:#666666;
}

.lbl-etiqueta-mobile-value{
    font-family: TTCRegular;
    font-size: 0.9rem;
    color:#000;
}

.lbl-etiqueta-2{
    font-family: TTCRegular;
    font-size: 1rem;
    color:#000;
    padding-right: 3px;
}

.txt-success{
    color: #a1a1a1;
}

.elemento-input-v2{
    border: 0px;
    background-color: #f7f7f7;
    box-shadow: 2px 2px 1px 0px #e6e6e6;
}

.elemento-textarea{
    margin-top: 0.5rem;
    padding: 0.1rem;
    white-space: pre-wrap;
    width: 100%;
    height: 100%;
    border-radius: 0.2rem;
    font-family: TTCRegular;
}

.elemento-txt{
    background-color: #e6e6e6;
}

.area-boton{
    text-align: center;
}

.IBtnAlertSuccess{
    width: 100%;
    border: 1px solid #3d3d3e;
    border-radius: 1rem;
    padding: 4px;
    display: table;
    cursor: pointer;
    margin: 5px;
    height: 2rem;
    background-color: #4C726A;
    font-family: TTCDemibold;
    color: #FFFFFF;
}

.IBtnAlertDanger > i {font-size: 1rem;  padding-left: 10px;margin-bottom: 3px;padding-top: 3px;  }
.IBtnAlertDanger > span { font-size: 1.1rem;cursor: pointer }

.IBtnAlertDanger{
    width: 100%;
    border: 1px solid #AF4F4F;
    border-radius: 1rem;
    padding: 4px;
    display: table;
    cursor: pointer;
    margin: 5px;
    height: 2rem;
    background-color: #AF4F4F;
    font-family: TTCDemibold;
    color: #FFFFFF;
}

.IBtnAlertSuccess > i {font-size: 1rem;  padding-left: 10px;margin-bottom: 3px;padding-top: 3px;  }
.IBtnAlertSuccess > span { font-size: 1.1rem;cursor: pointer }

.ITextbox{
    padding: 0.1rem;
    white-space: pre-wrap;
    width: 100%;
    height: 85%;
    border-radius: 0.2rem;
    font-family: TTCRegular;
    border: 0px;
    background-color: #f7f7f7;
    box-shadow: 2px 2px 1px 0px #e6e6e6;
    font-size: 1rem;
    outline: none;
}

.IGenericLabel{
    font-family: TTCMeditalic;
    font-size: 1rem;
    padding-right: 3px;
    font-weight:revert;
}

.IError{
    color: #B53A3A;
}

.IAlertTitle{
    color: white;
    font-family: TTCDemibold;
    font-size: 1.5rem;
}

.IAlertMessage{
    color: white;
    font-family: TTCMeditalic;
    font-size: 1.1rem;
}

.IAlertActions{
    text-align: right;
}

.ILabelAlert{
    padding-top: 20px;
}

.ILabelLink{
    cursor: pointer;
    padding-left: 4px;
}

.ILabelLink:hover{
    text-decoration: underline;
}

.ITabButtonActive{
    background-color: #EEEEEE;
    width: 25%;
    height: 2rem;
    text-align: center;
    font-family: TTCRegular;
    font-size: 1.2rem;
    color: #4082A8;
    border: 0px;
    cursor: pointer;
    border: 0px solid #EEEEEE;
    border-top-left-radius: 0.5rem ;
    border-top-right-radius: 0.5rem;
}

.ITabButtonInactive{
    background-color: #DCF1FF;
    width: 25%;
    text-align: center;
    font-family: TTCRegular;
    font-size: 1.2rem;
    color: #000000;
    border-radius: 0.5rem;
    border: 0px;
    cursor: pointer;
    margin-left: 0.2rem;
    margin-right: 0.2rem;
}

.IPaginatorSection{
    background-color: #e6e6e6;
    border-radius: 0.5rem;
    text-align: center;
    margin: auto ;
    padding-left: 1%;
    padding-right: 1%;
}

.IBtnPaginate{
    text-align: center;
    color: #555;
    font-family: TTCRegular;
    cursor: pointer;
    background-color: transparent;
    text-align: center;
}

.IBtnPaginate:hover{
    color: #4082A8;
}

.IBtnPaginate > label {
    font-size: 1rem;
    cursor: pointer;
}

.IBtnPaginate > i {
    font-size: 0.9rem;
    cursor: pointer;
    padding-left: 5px;
}

.ICellPaginateItem{
    padding-left: 1rem;
}

.IColumnCenter{
    text-align: center;
}
input[type="search"] {
    -webkit-appearance: none;
    appearance: none;
    -moz-appearance: none;
}

.ITxtEncontrar{
    border-radius: 25px;
    background-color: #f2f2f2;
    border-color:#f2f2f2;
    font-family: TTCItalic;
    font-size: 16px ;
    padding-right: 0px;
    padding-left: 15px;
    padding-top: 3px;
    background-image: url("assets/alpha-theme/images/icons/btnbuscar.png");
    background-size: 1rem ;
    background-position-y: 50%;
    background-position-x: 97%;
    background-repeat: no-repeat;
    -webkit-appearance: none;
    outline: none;
    width: 100%;
}

.ITxtEncontrar:focus-visible{
    background-image:none;
    border:3px solid #f2f2f2;

}

.IRadioTable {
    background-color: #F0FBFF;
    width: 100%;
    padding: 1rem;
    border-radius: 1rem;
}

.IRadioTable td {
    font-family: TTCRegular;
    font-size: 1rem;
    border-bottom: 3px solid #E4F2F9;
}

.IRowPlazos {
    border-bottom: 5px solid #3d3d3e;
}

.ICardSvG{
    margin: 0.5rem;
}

.ICardSvG2{
    margin: 0.2rem;
}

.IGenericLabel-2f{
    font-family: TTCBold;
    font-size: 2rem;
    padding-right: 3px;
}

.IGenericLabel-1-5f{
    font-family: TTCBold;
    font-size: 1.5rem;
    padding-right: 3px;
}

.IGenericLabel-sm{
    font-family: TTCLight;
    font-size: 0.9rem;
    padding-right: 3px;
    font-weight:revert;
}

.IGenericLabel-Normal{
    font-family: TTCLight;
    font-size: 1rem;
    padding-right: 3px;
    font-weight: bold;
}

.IGenericLabel-Bold{
    font-family: TTCRegular;
    font-size: 1rem;
    padding-right: 3px;
}

.pad-l-1{
    padding-left: 1rem;
}

.IBarcode{
    width: 20%;
}

.icon-size-2{
    font-size: 2rem;
}
.icon-size-1-5{
    font-size: 1.5rem;
    color: #5D8D80;
    cursor: pointer;
    padding-top: 3px;
}

.icon-size-black-1-5{
    font-size: 1rem;
    cursor: pointer;
}

.IExitosoLabel{
    font-family: TTCRegular;
    font-size: 2rem;
    padding-right: 3px;
    font-weight:bold;
    color: #5fb48c;
}

.IBtn-alpha-success-z2{
    width: 100%;
    border-radius: 0.8rem;
    padding: 4px;
    display: table;
    cursor: pointer;
    height: 30px;
    background-color: #5fb48c;
    border: 1px solid #5fb48c;
    font-family: TTCRegular;
    color: #FFFFFF;
    font-size: 1.2rem;
}

.IGenericLabel-Normal2 {
    font-family: TTCRegular;
    font-size: 1rem;
    padding-right: 3px;
}

.IEtiqueta-Alpha{
    font-size: 1.9rem;
    margin-top: 6px;
    position: relative;
    color: #e6e6e6;
}

.IRefCliente{
    color: #59C6EA;
    font-family: TTCBold;
}

.ITable-comercios{
    border-left: 3px solid #3d3d3e;
}

.vAlign{
    vertical-align: text-top;
    text-align: justify;
}

.ILabelImportant{
    font-family: TTCDemibold;
    color: red;
}

.ITable-recepit{
    border-collapse: collapse;
}

.IRow-recepit{
    border-bottom: 2px dotted #59C6EA;
}

.btn-alpha-info{
    width: 95%;
    border-radius: 1rem;
    display: table;
    cursor: pointer;
    margin: 5px;
    height: 27px;
    background-color: #37a6c4;
    border: 1px solid #37a6c4;
    font-family: TTCDemibold;
    color: #FFFFFF;
    font-size: 1rem;
}

.btn-alpha-info:hover{ background-color:#0d758e ;border-color:#0d758e  }
.btn-alpha-info i{  float: left; padding-left: 0.3rem;padding-top: 3px; font-size: 16px }

.btn-alpha-info-3{
    width: 95%;
    border-radius: 1rem;
    display: table;
    cursor: pointer;
    margin: 5px;
    height: 27px;
    background-color: #4A80A8;
    border: 1px solid #4A80A8;
    font-family: TTCDemibold;
    color: #FFFFFF;
    font-size: 1rem;
}

.btn-alpha-info-3:hover{ background-color:#2D5375 ;border-color:#2D5375  }
.btn-alpha-info-3 i{  float: left; padding-left: 0.3rem;padding-top: 3px; font-size: 16px }

.btn-alpha-info-1{
    width: 95%;
    border-radius: 1rem;
    display: table;
    cursor: pointer;
    margin: 5px;
    height: 27px;
    background-color: #37a6c4;
    border: 1px solid #37a6c4;
    font-family: TTCDemibold;
    color: #FFFFFF;
    font-size: 1rem;
}

.btn-alpha-info-1:hover{ background-color:#37a6ca ;border-color:#37a6ca  }
.btn-alpha-info-1 i{  float: left; padding-left: 0.3rem;padding-top: 3px; font-size: 16px }



.btn-alpha-gray-1{
    width: 95%;
    border-radius: 1rem;
    display: table;
    cursor: pointer;
    margin: 5px;
    height: 27px;
    background-color: #666666;
    border: 1px solid #666666;
    font-family: TTCDemibold;
    color: #FFFFFF;
    font-size: 1rem;
}

.Icon-filter{
    font-size: 1.5rem;
    background-color: transparent;
    border: 0px;
    color: #80C1AA;
}

.btn-alpha-gray-1:hover{ background-color:#5A5A5A ;border-color:#5A5A5A  }
.btn-alpha-gray-1 i{  float: left; padding-left: 0.3rem;padding-top: 3px; font-size: 16px }

.ITxtEncontrar-Banca{
    border-radius: 25px;
    background-color: #f2f2f2;
    border-color:#f2f2f2;
    font-family: TTCItalic;
    font-size: 16px ;
    padding-right: 0px;
    padding-left: 15px;
    margin-top: 5px;
    height: 27px;
    background-image: url("assets/alpha-theme/images/icons/btnbuscar.png");
    background-size: 1rem ;
    background-position-y: 50%;
    background-position-x: 97%;
    background-repeat: no-repeat;
    -webkit-appearance: none;
    outline: none;
    width: 100%;
}

.ITabButtonActive2{
    background-color: #EEEEEE;
    width: 15%;
    height: 3.5rem;
    text-align: center;
    font-family: TTCRegular;
    font-size: 1.2rem;
    color: #4082A8;
    border: 0px;
    cursor: pointer;
    border: 0px solid #EEEEEE;
    border-top-left-radius: 0.5rem ;
    border-top-right-radius: 0.5rem;
}

.ITabButtonInactive2{
    background-color: #DCF1FF;
    width: 15%;
    text-align: center;
    font-family: TTCRegular;
    font-size: 1.2rem;
    height: 3rem;
    color: #000000;
    border-radius: 0.5rem;
    border: 0px;
    cursor: pointer;
    margin-left: 0.2rem;
    margin-right: 0.2rem;
}

.IMovil-Display {
    cursor: pointer;
}

.icon-menu{
    color: #344767;
}

.table-alpha-filter-2 td {
    font-family: TTCRegular;
    font-size: 1rem;
    color: #000;
}

.table-alpha-filter-tr-pair{
    background-color: #DCF1FF;
}

.table-alpha-filter-2 tr td:first-child{
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.table-alpha-filter-2 tr td:last-child{
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.table-alpha-filter-2>tbody>tr.rowx:hover {
     background-color: #5F9BC4;
 }

.table-alpha-filter-2>tbody>tr.rowx:hover td {
    color: #fff;
}

.IBoton-3p-gris {
    width: 25pt;
    border-radius: 5px;
    height: 25px;
    background-color: #f7f7f7;
    border: 3px solid #f7f7f7;
    cursor: pointer;
    margin-left: 5px;
    margin-right: 5px;
    color: #fff;
    font-size: 1rem;
    box-shadow: none;
}

.IBtn-Busqueda{
    margin-right: 15px;
    float: right;
    display: table;
}

.IClickable{
    cursor: pointer;
}

.c-whithe{
    color: #a7a7a7;
    margin-right: 20px;
}

.sec-imagen
{
    padding-left: 5%;
}

.sec-titulos{
    padding-right: 5%;
    text-align: right;
}

.sec-titulos-rs{
    padding-top: 8%;
}

.mostrar-movil{
    display: none;
}

.bg-gris-1{
    background-color: #EDEDF0;
}

.m-0-cell{
    border: 2px solid #EDEDF0;
}

.t-white {
    color: #EEEEEE;
}

.rs-mobile{
    text-align: center;
    font-family: TTCDemibold;
    color: #4082A8;
}

@media (max-width: 768px) {

    .IMovil-Display {
        display: block;
        font-size: 0.8rem;
    }
    .sec-imagen
    {
        padding-left: 0;
    }
}

@media (min-width: 860px) {
    .clickable {
        cursor: pointer;
    }
}

@media (max-width: 845px) {
    .IMovil-Display {
        font-size: 0.8rem;
    }

    .Img-PrintInvoice{
        content: url("assets/alpha-theme/images/cards/Cobranza-2v2.png")
    }

    .Img-PrintReference{
        width: 95%;
    }

    .sec-imagen{
        text-align: center;
    }

    .sec-titulos-rs{
        padding-top: 1%;
    }

    .mostrar-movil{
        display: block;
    }

    .ocultar-movil{
        display: none;
    }
    .sec-titulos{
        text-align: center;
        margin-top: 5%;
    }

    .sec-indicaciones{
        text-align: left;
        font-size: 0.8rem;
    }

    .btn-alpha-black{
        width: 95%;
        border: 1px solid #4d4d4d;
        border-radius: 1rem;
        /**margin: 4px;**/
        padding: 4px;
        display: table;
        cursor: pointer;
        height: 27px;
        background-color: #4d4d4d;
        font-family: TTCDemibold;
        color: #EEEEEE;
        font-size: 1rem;
    }

    .btn-alpha-success-2{
        width: 95%;
        border: 1px solid #5fb48c;
        border-radius: 1rem;
        padding: 4px;
        display: table;
        cursor: pointer;
        height: 27px;
        background-color: #5fb48c;
        font-family: TTCDemibold;
        color: #FFFFFF;
        font-size: 1rem;
    }

    .img-login{
        width: 230vh;
    }

    .tabla-documento-por-pagar
    {
        width: 97%;
        margin-left: 0px !important;
    }

}

.img-login{
    width: 30vh;
}

.tabla-documento-por-pagar>tbody>tr.rowx:hover .icon-size-1-5{
    color: #fff !important;
}

.checkMobile{
    position: relative;
    text-align: right;
}

.mini-sections{
    width: 100%;
}

.cell-documento{
    width:100%;
    padding:0px;
    display:inline-flex;
    text-align: left;
}

.cell-documento-parte11
{
    width: 50%;
}

.cell-documento-parte22
{
    width: 50%;
    display: flex;
    align-items: center;
}

.cell-documento-parte1
{
    width: 85%;
}

.cell-documento-parte2
{
    width: 15%;
    display: flex;
    align-items: center;
}
.mini-monto{
    font-family: TTCRegular;
    font-size: 1rem;
    color:#2D5375;
    text-align: right;
    padding-right: 3px;
}




