@media all and (max-width: 767px){
    {
        padding:0px 6px
    }
    .tabla-responsiva-movil>thead{
        display:none
    }
    .tabla-responsiva-movil>tbody>tr{
        display:table;
        border:0px;
        border-radius:5px;
        width:100%;
        margin-bottom:5px;
        padding-left:6px
    }

    .tabla-responsiva-movil>tfoot>tr{
        display:table;
        border:0px;
        border-radius:5px;
        width:100%;
        margin-bottom:5px;
        padding-left:6px
    }

    .tabla-responsiva-movil>tbody>tr:first-child{
        border-top:0px
    }
    .tabla-responsiva-movil>tbody>tr>td{
        float:left;
        border:none;
        border-collapse:collapse;
        height:auto;
        padding:0px 3px;
        border:none
    }
    .tabla-responsiva-movil>tbody>tr .etiqueta-before::before{
        content:attr(data-title);
        font-weight:bold;
        margin-right:2px;
        color:black
    }
    .tabla-responsiva-movil.tabla-listado-seguimiento>tbody>tr{
        padding-right:25px;
        position:relative
    }
    .tabla-responsiva-movil.tabla-listado-seguimiento>tbody>tr>td.td-icono-seguimiento{
        position:absolute;
        right:4px;
        top:10px
    }
    .tabla-detalle-documentos.tabla-responsiva-movil>thead{
        display:none
    }
    .tabla-detalle-documentos.tabla-responsiva-movil>tbody>tr{
        display:table;
        border:0px;
        border-top:none;
        border-bottom-left-radius:5px;
        border-bottom-right-radius:5px;
        width:100%;
        margin-bottom:5px
    }
    .tabla-detalle-documentos.tabla-responsiva-movil>tbody>tr:first-child{
        border-top:0px
    }
    .tabla-detalle-documentos.tabla-responsiva-movil>tbody>tr>td{
        float:left;
        border:none;
        border-collapse:collapse;
        height:auto;
        padding:0px;
        padding-right:2px;
        padding-left:2px;
        border:none;
        text-align:left
    }
    .tabla-detalle-documentos.tabla-responsiva-movil>tbody>tr>td *{
        border-collapse:initial
    }
    .tabla-detalle-documentos.tabla-responsiva-movil>tbody>tr>td.td-imagen,.tabla-detalle-documentos.tabla-responsiva-movil>tbody>tr>td.td-checkbox,.tabla-detalle-documentos.tabla-responsiva-movil>tbody>tr>td.td-15{
        float:none
    }
    .tabla-detalle-documentos.tabla-responsiva-movil>tbody>tr>td.td-producto{
        max-width:none
    }
    .tabla-detalle-documentos.tabla-responsiva-movil>tbody>tr>td.td-kits{
        float:none;
        text-align:center
    }
    .tabla-detalle-documentos.tabla-responsiva-movil>tbody>tr>td[class*="col-"]{
        float:left;
        border:none
    }
    .tabla-detalle-documentos.tabla-responsiva-movil>tbody>tr>td .etiqueta-before::before{
        content:attr(data-title);
        font-weight:bold;
        margin-right:2px;
        color:black
    }
    .timePickerSai{
        position:absolute
    }
    .timePickerSai>ul>li{
        width:auto;
        max-width:45px;
        min-width:20px
    }
    .etiqueta-before::before{
        content:attr(data-title)
    }
    .contenedor-padre{
        width:95%
    }
    .contenedor-fixed-head .contenido-lateral{
        width:20%
    }
    .contenedor-fixed-head .contenido-lateral .img-logo{
        width:60px;
        margin-top:15px
    }
    .contenedor-fixed-head .contenido-lateral .boton-salir{
        padding-left:0;
        background-image:none
    }
    .contenedor-fixed-head .contenido-central{
        width:60%
    }
    .contenedor-fixed-head .contenido-central .titulo-informacion{
        font-size:9px
    }
    .contenedor-fixed-head .contenido-central .titulo-bienvenida{
        font-size:25px;
        padding-top:12px
    }
    .etiqueta-30{
        font-size:25px
    }
}
@media all and (min-width: 1301px) and (max-width: 2000px){
    >thead>tr>th,>thead>tr>td,.tabla-detalle-documentos>thead>tr>th,.tabla-detalle-documentos>thead>tr>td{
        font-size:15px
    }
    >tbody>tr>td,.tabla-detalle-documentos>tbody>tr>td{
        font-size:14px
    }
    .tabla-total-documentos>tbody>tr>td{
        font-size:16px
    }
    .tabla-total-documentos>tbody>tr.tr-totales>td{
        font-size:18px
    }
    .etiqueta-30{
        font-size:30px !important
    }
}
@media all and (min-width: 2001px) and (max-width: 3000px){
    >thead>tr>th,>thead>tr>td,.tabla-detalle-documentos>thead>tr>th,.tabla-detalle-documentos>thead>tr>td{
        font-size:18px
    }
    >tbody>tr>td,.tabla-detalle-documentos>tbody>tr>td{
        font-size:16px
    }
    .tabla-total-documentos>tbody>tr>td{
        font-size:18px
    }
    .tabla-total-documentos>tbody>tr.tr-totales>td{
        font-size:20px
    }
    .etiqueta-30{
        font-size:30px !important
    }
}
@media all and (min-width: 3001px){
    >thead>tr>th,>thead>tr>td,.tabla-detalle-documentos>thead>tr>th,.tabla-detalle-documentos>thead>tr>td{
        font-size:20px
    }
    >tbody>tr>td,.tabla-detalle-documentos>tbody>tr>td{
        font-size:18px
    }
    .tabla-total-documentos>tbody>tr>td{
        font-size:20px
    }
    .tabla-total-documentos>tbody>tr.tr-totales>td{
        font-size:24px
    }
    .etiqueta-30{
        font-size:32px !important
    }
}
@media print{
    .tabla-detalle-documentos{
        width:100%;
        border-spacing:1px;
        text-align:center
    }
    .tabla-detalle-documentos.tabla-responsiva-movil>thead{
        display:table-header-group
    }
    .tabla-detalle-documentos.tabla-responsiva-movil>tbody>tr{
        display:table-row
    }
    .tabla-detalle-documentos.tabla-responsiva-movil>tbody>tr>td{
        display:table-cell;
        float:none;
        border-bottom:0px;
        word-break:normal;
        font-size:12px;
        text-align:center
    }
    .tabla-detalle-documentos.tabla-responsiva-movil>tbody>tr>td[class*="col-"]{
        display:table-cell;
        float:none
    }
    .tabla-detalle-documentos.tabla-responsiva-movil>tbody>tr>td.td-primera{
        border-left:0px;
        border-bottom-left-radius:5px
    }
    .tabla-detalle-documentos.tabla-responsiva-movil>tbody>tr>td.td-ultima{
        border-right:0px;
        border-bottom-right-radius:5px
    }
    .tabla-detalle-documentos.tabla-responsiva-movil>tbody>tr>td.td-imagen{
        width:54px
    }
    .tabla-detalle-documentos.tabla-responsiva-movil>tbody>tr>td.td-producto{
        max-width:250px;
        text-align:left
    }
    .tabla-detalle-documentos.tabla-responsiva-movil>tbody>tr>td.td-numerico{
        text-align:right
    }
    .tabla-detalle-documentos.tabla-responsiva-movil>tbody>tr>td .etiqueta-before::before{
        content:""
    }
}
@media (max-width: 767px){
    .tabla-detalle-documentos.tabla-responsiva-movil>tbody>tr:hover>td .etiqueta-before::before{
        color:#fff
    }
}
