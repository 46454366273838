
.loader {
    width: 115px;
    height: 115px;
    border: 5px solid transparent;
    border-top-color: #59c6ea;
    border-bottom-color: #80c1aa;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    position: fixed;
    animation: rotationLoading 2s ease-in-out infinite;
}

@keyframes rotationLoading {
    0% {
        transform: rotate(0deg)
    }

    100% {
        transform: rotate(360deg)
    }
}

.contenedor-loading {
    z-index: 1000;
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    width: 98%;
    height: 98%;
    background-color: rgba(0,0,0,0.5);
    text-align: center;
}


.contenedor-loading-animacion img{
    padding-left: 3%;
    padding-top: 5%;
}